<template>
  <v-hover
    v-slot="{ hover }"
  >
    <v-card
      class="mx-auto"
      :class="client.invitationFromProfessional === 'declined' ? 'mt-8' : ''"
      :elevation="hover ? 6 : 2"
      :ripple="false"
      :outlined="(client.clientType === 'company' || client.clientType === 'individual') && client.invitationFromProfessional !== 'declined'"
      :min-height="client.invitationFromProfessional === 'declined' ? 300 : ''"
      @click.stop="openOverview(client)"
    >
      <v-toolbar
        v-if="client.invitationFromProfessional && (client.invitationFromProfessional === 'declined' || client.invitationFromProfessional === 'pending')"
        flat
        :color="client.invitationFromProfessional === 'declined' ? 'error' : 'accent'"
      >
        <v-icon
          v-if="client.invitationFromProfessional === 'declined'"
          class="mr-3"
          color="white"
        >
          mdi-alert-octagon
        </v-icon>
        <v-icon
          v-if="client.invitationFromProfessional === 'pending'"
          class="mb-1 mr-2"
          color="white"
        >
          mdi-account-clock-outline
        </v-icon>
        <span
          v-if="client.invitationFromProfessional === 'pending'"
          style="color: white;"
        >
          {{ $t('common|pending_invitation') }}...
        </span>
        <span
          v-if="client.invitationFromProfessional === 'declined'"
          style="color: white;"
        >
          {{ $t('common|invitation_declined') }}
        </span>
        <v-spacer />
        <v-btn
          v-if="client.invitationFromProfessional === 'pending'"
          rounded
          x-small
          outlined
          color="primary"
          @click="cancelInvitation(client)"
        >
          cancel invitation
        </v-btn>
        <v-btn
          v-if="client.invitationFromProfessional === 'declined'"
          icon
          right
          @click.stop="deleteClient(client)"
        >
          <v-icon>
            {{ icons.trash }}
          </v-icon>
        </v-btn>
      </v-toolbar>

      <div
        :class="client.invitationFromProfessional &&
          (client.invitationFromProfessional === 'declined' ||
          client.invitationFromProfessional === 'pending') ? 'disabled-card' : ''"
      >
        <v-card-title
          v-if="client.invitationFromProfessional && client.invitationFromProfessional !== 'pending' && client.invitationFromProfessional !== 'declined'"
          class="d-flex align-center"
        >
          {{ clientName }}
        </v-card-title>
        <v-card-title v-if="client.invitationFromProfessional === 'pending' || client.invitationFromProfessional === 'declined'">
          {{ client.clientData.email }}
        </v-card-title>

        <div
          v-if="client.invitationFromProfessional === 'accepted' && clientName === 'Not Set' && client.invitationFromProfessional !== 'declined'"
          class="mx-4 mt-n4"
          style="font-size: 15px;"
        >
          {{ $t('clients|status_client_data') }}: {{ $t('common|pending') }}...<br>
          {{ $t('clients|waiting_for_user') }}...
        </div>
        <v-card-title
          v-if="!client.invitationFromProfessional && client.clientData"
          class="d-flex align-center"
        >
          {{ clientName }}
        </v-card-title>
        <v-btn
          v-if="client.pinned.includes(account._id) && !client.invitationFromProfessional && !$vuetify.breakpoint.mobile"
          icon
          style="position: absolute; top: 15px; right: 20px;"
          @click.stop="removePinFromDashboard(client)"
        >
          <v-icon>
            mdi-pin
          </v-icon>
        </v-btn>
        <v-btn
          v-if="!client.pinned.includes(account._id) && !client.invitationFromProfessional && !$vuetify.breakpoint.mobile"
          icon
          :disabled="client.invitationFromProfessional === 'declined'"
          style="position: absolute; top: 15px; right: 20px;"
          @click.stop="pinToDashboard(client)"
        >
          <v-icon>
            mdi-pin-outline
          </v-icon>
        </v-btn>
        <v-card-text class="mt-n5">
          <div>
            {{ $t('clients|profile_type') }}:
            <v-chip
              x-small
              outlined
              color="primary"
            >
              {{ client.realClient ? `${$t('clients|client_account')}` : `${$t('clients|locally_created_client_profile')}` }}
            </v-chip>
          </div>
          <div>
            {{ $t('clients|client_type') }}: {{ $t(`clients|${client.clientType}`) }}
          </div>
          <div>
            {{ $t('common|date_created') }}: {{ $options.filters.date(client.createdAt) }}
          </div>
          <div>
            {{ $t('common|email') }}: {{ client.clientData.email }}
          <!-- {{ $t('common|date_updated') }}: {{ $options.filters.dateTime(client.updatedAt) }} -->
          </div>

          <!-- style="height:65px; width:300px; position:relative; bottom:0px; top:0px; overflow:auto;" -->
          <div>
            <span>
              {{ $t('cases|cases') }}:
            </span>
            <v-chip
              v-for="(c, index) in clientCases"
              :key="index"
              class="ma-1"
              x-small
              color="primary"
              outlined
              @click.stop="routeToCase(c._id)"
            >
              <v-icon
                left
                small
              >
                mdi-briefcase-edit
              </v-icon>
              {{ c.caseName }}
            </v-chip>
          </div>
        </v-card-text>
        <v-card-actions
          v-if="client.invitationFromProfessional !== 'declined' || client.invitationFromProfessional === 'pending'"
        >
          <v-btn
            color="primary"
            outlined
            rounded
            small
            class="ml-n2"
            @click.stop="routeToClient(client._id)"
          >
            <v-icon
              small
              class="mr-1"
            >
              mdi-arrow-top-right
            </v-icon>
            {{ $t('actions|open') }}
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="client.pinned.includes(account._id) && !client.invitationFromProfessional && $vuetify.breakpoint.mobile"
            icon
            @click.stop="removePinFromDashboard(client)"
          >
            <v-icon>
              mdi-pin
            </v-icon>
          </v-btn>
          <v-btn
            v-if="!client.pinned.includes(account._id) && !client.invitationFromProfessional && $vuetify.breakpoint.mobile"
            icon
            :disabled="client.invitationFromProfessional === 'declined'"
            @click.stop="pinToDashboard(client)"
          >
            <v-icon>
              mdi-pin-outline
            </v-icon>
          </v-btn>
          <v-btn
            text
            icon
            @click.stop="deleteClient(client)"
          >
            <v-icon>
              {{ icons.trash }}
            </v-icon>
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-hover>
</template>

<script>
import { mdiTrashCanOutline } from '@mdi/js'
import ClientCardMixin from './ClientMixins/ClientCardMixin'

export default {
  mixins: [ClientCardMixin],
  data () {
    return {
      icons: {
        trash: mdiTrashCanOutline
      }
    }
  }
}
</script>

<style scoped>
  .disabled-card {
    pointer-events: none;
    opacity: 0.5;
  }
  .cancel-btn {
    pointer-events: auto;
    opacity: 1;
  }
</style>
