<template>
  <v-container
    class="px-0"
    fluid
    :style="$vuetify.breakpoint.mobile ? `margin-bottom: 48px;` : `max-height: ${vuetifyHeight - 140 + 'px'}; overflow-y: hidden; overflow-x: hidden;`"
  >
    <div
      v-if="!$vuetify.breakpoint.mobile"
      style="width: 100%;"
    >
      <ClientsFilterBar
        v-model="clientSearch"
        :loading="clientsLoading"
        :actual-tab="actualTab"
        :account="account"
        :company="company"
        :group="group"
        @viewSelection="changeView"
      />
    </div>
    <div
      v-if="isMyTab && !clients.length || isCompanyTab && !companyClients.length || isGroupTab && !groupClients.length"
    >
      <v-container
        class="pa-2"
        :style="`height: ${vuetifyHeight - 140 + 'px'}; overflow-y: hidden; overflow-x: hidden;`"
      >
        <template v-if="isMyTab && !clients.length">
          <div
            class="center"
          >
            <v-icon
              color="primary"
              class="mb-1"
            >
              mdi-account-outline
            </v-icon>
            <span class="ml-2 mb-n1">{{ $t('clients|no_client') }}</span>
          </div>
        </template>
        <template v-if="isCompanyTab && !companyClients.length">
          <div
            class="center"
          >
            <v-icon
              color="primary"
              class="mb-1"
            >
              mdi-account-outline
            </v-icon>
            <span class="ml-2 mb-n1">{{ $t('clients|no_client') }}</span>
          </div>
        </template>
        <template v-if="isGroupTab && !groupClients.length">
          <div
            class="center"
          >
            <v-icon
              color="primary"
              class="mb-1"
            >
              mdi-account-outline
            </v-icon>
            <span class="ml-2 mb-n1">{{ $t('clients|no_client') }}</span>
          </div>
        </template>
      </v-container>
    </div>
    <div
      v-else
      class="mt-n2"
    >
      <v-container
        v-if="selection === 'all'"
        fluid
        class="pa-2 mb-2"
      >
        <v-row style="overflow-y: auto;">
          <v-col
            :cols="$vuetify.breakpoint.mobile ? '12' : '4'"
          >
            <v-card
              flat
              :style="$vuetify.breakpoint.mobile ? 'min-height: 300px;' : `height: ${vuetifyHeight - 225 + 'px'}; overflow-y: auto; overflow-x: hidden;`"
            >
              <v-card-text
                style="font-size:1rem"
                class="text-center"
              >
                {{ $t('clients|recently_added') }}
              </v-card-text>
              <div v-if="searchedClients && searchedClients.length">
                <div
                  v-for="(client, clIndex0) in searchedClients"
                  :key="clIndex0"
                  class="ma-4"
                >
                  <ClientCard
                    :client="client"
                    :actual-tab="actualTab"
                  />
                </div>
              </div>
              <div
                v-else
                class="center"
              >
                <v-icon
                  color="primary"
                  class="mb-1"
                >
                  mdi-account-outline
                </v-icon>
                <span class="ml-2 mb-n1">{{ $t('clients|no_client') }}</span>
              </div>
            </v-card>
          </v-col>
          <v-col
            :cols="$vuetify.breakpoint.mobile ? '12' : '4'"
            :class="$vuetify.breakpoint.mobile ? 'pa-0' : ''"
          >
            <v-card
              flat
              color="background"
              class="pb-8"
              :style="$vuetify.breakpoint.mobile ? 'min-height: 300px;' : `height: ${vuetifyHeight - 225 + 'px'}; overflow-y: auto; overflow-x: hidden;`"
            >
              <v-card-text
                style="font-size:1rem"
                class="text-center"
              >
                {{ $t('clients|companies') }}
              </v-card-text>
              <div v-if="filteredClientCompany && filteredClientCompany.length">
                <div
                  v-for="(client, clIndex1) in filteredClientCompany"
                  :key="clIndex1"
                  class="ma-4"
                >
                  <ClientCard
                    :client="client"
                    :actual-tab="actualTab"
                  />
                </div>
              </div>
              <div
                v-else
                class="center"
              >
                <v-icon
                  color="primary"
                  class="mb-1"
                >
                  mdi-account-outline
                </v-icon>
                <span class="ml-2 mb-n1">{{ $t('clients|no_client') }}</span>
              </div>
            </v-card>
          </v-col>
          <v-col
            :cols="$vuetify.breakpoint.mobile ? '12' : '4'"
          >
            <v-card
              flat
              :style="$vuetify.breakpoint.mobile ? 'min-height: 300px;' : `height: ${vuetifyHeight - 225 + 'px'}; overflow-y: auto; overflow-x: hidden;`"
            >
              <v-card-text
                style="font-size:1rem"
                class="text-center"
              >
                {{ $t('clients|individual_clients') }}
              </v-card-text>
              <div v-if="filteredClientIndividual && filteredClientIndividual.length">
                <div
                  v-for="(client, clIndex2) in filteredClientIndividual"
                  :key="clIndex2"
                  class="ma-4"
                >
                  <ClientCard
                    :client="client"
                    :actual-tab="actualTab"
                  />
                </div>
              </div>
              <div
                v-else
                class="center"
              >
                <v-icon
                  color="primary"
                  class="mb-1"
                >
                  mdi-account-outline
                </v-icon>
                <span class="ml-2 mb-n1">{{ $t('clients|no_client') }}</span>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container
        v-if="selection === 'companies'"
        fluid
        class="pa-2 mb-2"
      >
        <v-card
          flat
          :style="$vuetify.breakpoint.mobile ? 'min-height: 300px;' : `height: ${vuetifyHeight - 225 + 'px'}; overflow-y: auto; overflow-x: hidden;`"
        >
          <v-card-text
            style="font-size:1rem"
            class="text-left"
          >
            {{ $t('clients|companies') }}
          </v-card-text>
          <div
            class="px-2 d-flex"
          >
            <v-row
              v-if="filteredClientCompany && filteredClientCompany.length"
              :justify="$vuetify.breakpoint.mobile ? 'center' : 'start'"
            >
              <v-col
                v-for="(client, clIndex3) in filteredClientCompany"
                :key="clIndex3"
                class="ma-2"
                :cols="$vuetify.breakpoint.mobile ? '12' : '4'"
              >
                <ClientCard
                  :style="$vuetify.breakpoint.mobile ? '' : `width: 480px;`"
                  :client="client"
                  :actual-tab="actualTab"
                />
              </v-col>
            </v-row>
            <div
              v-else
              class="center"
            >
              <v-icon
                color="primary"
                class="mb-1"
              >
                mdi-account-outline
              </v-icon>
              <span class="ml-2 mb-n1">{{ $t('clients|no_client') }}</span>
            </div>
          </div>
        </v-card>
      </v-container>
      <v-container
        v-if="selection === 'individual_clients'"
        fluid
        class="pa-2 mb-2"
      >
        <v-card
          flat
          :style="$vuetify.breakpoint.mobile ? 'min-height: 300px;' : `height: ${vuetifyHeight - 225 + 'px'}; overflow-y: auto; overflow-x: hidden;`"
        >
          <v-card-text
            style="font-size:1rem"
            class="text-left"
          >
            {{ $t('clients|individual_clients') }}
          </v-card-text>
          <v-row
            v-if="filteredClientIndividual && filteredClientIndividual.length"
            :justify="$vuetify.breakpoint.mobile ? 'center' : 'start'"
          >
            <v-col
              v-for="(client, clIndex4) in filteredClientIndividual"
              :key="clIndex4"
              class="ma-2"
              :cols="$vuetify.breakpoint.mobile ? '12' : '4'"
            >
              <ClientCard
                :style="$vuetify.breakpoint.mobile ? '' : `width: 480px;`"
                :client="client"
                :actual-tab="actualTab"
              />
            </v-col>
          </v-row>
          <div
            v-else
            class="center"
          >
            <v-icon
              color="primary"
              class="mb-1"
            >
              mdi-account-outline
            </v-icon>
            <span class="ml-2 mb-n1">{{ $t('clients|no_client') }}</span>
          </div>
        </v-card>
      </v-container>
      <v-container
        v-if="selection === 'recently_added'"
        fluid
        class="pa-2 mb-2"
      >
        <v-card
          flat
          :style="$vuetify.breakpoint.mobile ? 'min-height: 300px;' : `height: ${vuetifyHeight - 225 + 'px'}; overflow-y: auto; overflow-x: hidden;`"
        >
          <v-card-text
            style="font-size:1rem"
            class="text-left"
          >
            {{ $t('clients|recently_added') }}
          </v-card-text>
          <v-row
            v-if="searchedClients && searchedClients.length"
            :justify="$vuetify.breakpoint.mobile ? 'center' : 'start'"
          >
            <v-col
              v-for="(client, clIndex5) in searchedClients"
              :key="clIndex5"
              :cols="$vuetify.breakpoint.mobile ? '12' : '4'"
            >
              <ClientCard
                :style="$vuetify.breakpoint.mobile ? '' : `width: 480px;`"
                :client="client"
                :actual-tab="actualTab"
              />
            </v-col>
          </v-row>
          <div
            v-else
            class="center"
          >
            <v-icon
              color="primary"
              class="mb-1"
            >
              mdi-account-outline
            </v-icon>
            <span class="ml-2 mb-n1">{{ $t('clients|no_client') }}</span>
          </div>
        </v-card>
      </v-container>
    </div>
    <div
      v-if="$vuetify.breakpoint.mobile"
    >
      <ClientsFilterBar
        v-model="clientSearch"
        :loading="clientsLoading"
        :actual-tab="actualTab"
        :account="account"
        :company="company"
        :group="group"
        class="bottom-element-clients-tabs"
        @viewSelection="changeView"
      />
    </div>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import moment from 'moment'
import ClientsFilterBar from './ClientsFilterBar.vue'
import ClientCard from './ClientCard.vue'

const sortByUpdated = (items) => items.sort((a, b) => moment.utc(b.createdAt).diff(moment.utc(a.createdAt)))

export default {
  components: {
    ClientsFilterBar,
    ClientCard
  },
  props: {
    group: {
      type: Object,
      default: null
    },
    company: {
      type: Object,
      default: null
    },
    isCompanyTab: {
      type: Boolean,
      default: false
    },
    isGroupTab: {
      type: Boolean,
      default: false
    },
    isMyTab: {
      type: Boolean,
      default: false
    },
    isLavviraTab: {
      type: Boolean,
      default: false
    },
    isSingleAcc: {
      type: Boolean,
      default: false
    },
    isSharedWithMe: {
      type: Boolean,
      default: false
    },
    userRole: {
      type: String,
      default: undefined
    },
    actualTab: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      clientSearch: '',
      selection: localStorage.getItem('clientSectionView') || 'all'
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      clients: state => state.clients.clients,
      companyClients: state => state.companyClients.companyClients,
      groupClients: state => state.groupClients.groupClients,
      clientsLoading: state => state.clients.loading
    }),
    vuetifyHeight () {
      return this.$vuetify.breakpoint.height
    },
    _clients () {
      let actualClients = []
      if (this.isMyTab) {
        actualClients = this.clients
      }
      if (this.isCompanyTab && this.company) {
        actualClients = this.companyClients
      }
      if (this.isGroupTab && this.group) {
        for (let i = 0; i < this.groupClients.length; i++) {
          const client = this.groupClients[i]
          client.sharedWith.groups.forEach(group => {
            if (this.group._id === group) {
              actualClients.push(client)
            }
          })
        }
      }
      return actualClients
    },
    _searchedClients () {
      if (!this.clientSearch && !this.clientSearch.length) return this._clients
      if (this.clientSearch && this.clientSearch.length) {
        return this._clients.filter((c) => {
          if (c.clientType === 'company') {
            return c.clientData.company_name.toLowerCase().includes(this.clientSearch.toLowerCase())
          } else if (c.clientType === 'individual' && c.clientData.given_names && (c.clientData.surname === null)) {
            return c.clientData.given_names.toLowerCase().includes(this.clientSearch.toLowerCase())
          } else if (c.clientType === 'individual' && (c.clientData.given_names === null) && c.clientData.surname) {
            return c.clientData.surname.toLowerCase().includes(this.clientSearch.toLowerCase())
          } else if (c.clientType === 'individual' && c.clientData.given_names && c.clientData.surname) {
            return c.clientData.given_names.toLowerCase().includes(this.clientSearch.toLowerCase()) || c.clientData.surname.toLowerCase().includes(this.clientSearch.toLowerCase())
          }
        })
      }
      return this._clients
    },
    searchedClients () {
      sortByUpdated(this._searchedClients)
      return this._searchedClients
    },
    filteredClientCompany () {
      const companyClient = this.searchedClients.filter(c => c.clientType === 'company')
      return companyClient
    },
    filteredClientIndividual () {
      const individualClient = this.searchedClients.filter(c => c.clientType === 'individual')
      return individualClient
    }
  },
  async created () {
    await this.fetchClients()
  },
  methods: {
    ...mapActions({
      fetchClients: 'clients/fetchClients'
    }),
    changeView (item) {
      this.selection = item.type
      localStorage.setItem('clientSectionView', item.type)
    }
  }
}
</script>

<style scoped>
  .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .bottom-element-clients-tabs {
    position: fixed;
    bottom: 0px;
    width: 100%;
  }
</style>
