<template>
  <div>
    <!-- MOBILE -->
    <v-toolbar
      v-if="$vuetify.breakpoint.mobile"
      flat
      color="primary"
    >
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        bottom
        color="accent"
      />
      <v-text-field
        :value="value"
        prepend-inner-icon="mdi-magnify"
        :label="$t('actions|search_clients')"
        hide-details
        dense
        dark
        class="my-2"
        @input="event => this.$emit('input', event)"
      />
      <v-menu
        rounded
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            dark
            v-bind="attrs"
            class="mt-1"
            v-on="on"
          >
            <v-icon>
              mdi-filter-outline
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in categories"
            :key="index"
            @click="$emit('viewSelection', item)"
          >
            <v-list-item-title>{{ item.translation }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        icon
        class="ml-n4"
        dark
        :disabled="!activeButton(account, company, group, actualTab)"
        @click="addClientAction('mobile')"
      >
        <v-icon>mdi-account-plus-outline</v-icon>
      </v-btn>
    </v-toolbar>

    <!-- DESKTOP -->
    <v-toolbar
      v-else
      flat
      class="mt-n3"
      color="lightBackground"
    >
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        bottom
        color="deep-purple accent-4"
      />
      <v-text-field
        :solo-inverted="soloInverted"
        :solo="solo"
        :value="value"
        prepend-inner-icon="mdi-magnify"
        :label="$t('actions|search_clients')"
        rounded
        hide-details
        dense
        class="shrink"
        @focus="soloInverted = true; solo = false"
        @blur="soloInverted = false; solo = true"
        @input="event => this.$emit('input', event)"
      />
      <v-menu
        rounded
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            outlined
            rounded
            dark
            small
            class="ml-2 pl-2 pr-1"
            v-bind="attrs"
            v-on="on"
          >
            {{ $t('common|filter') }}
            <v-icon class="ml-1">
              {{ icons.arrowDown }}
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in categories"
            :key="index"
            @click="$emit('viewSelection', item)"
          >
            <v-list-item-title>{{ item.translation }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-spacer />
      <v-btn
        color="primary"
        rounded
        small
        :disabled="!activeButton(account, company, group, actualTab)"
        @click="addClientAction('desktop')"
      >
        <v-icon
          small
          class="mr-1"
        >
          mdi-account-plus-outline
        </v-icon>
        {{ $t('clients|add_new') }}
      </v-btn>
    </v-toolbar>
  </div>
</template>

<script>
import { mdiChevronDown } from '@mdi/js'
import { EventBus } from '@/utils/EventBus'
import { userHasAccess } from '@/utils/utils'

export default {
  props: {
    value: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    actualTab: {
      type: Object,
      default: null
    },
    account: {
      type: Object,
      default: null
    },
    group: {
      type: Object,
      default: null
    },
    company: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      icons: {
        arrowDown: mdiChevronDown
      },
      selected: '',
      soloInverted: false,
      solo: true
    }
  },
  computed: {
    categories () {
      return [
        {
          type: 'companies',
          translation: this.$t('clients|companies')
        },
        {
          type: 'individual_clients',
          translation: this.$t('clients|individual_clients')
        },
        {
          type: 'recently_added',
          translation: this.$t('clients|recently_added')
        },
        {
          type: 'all',
          translation: this.$t('common|all')
        }
      ]
    }
  },
  methods: {
    addClientAction (input) {
      if (input && input === 'mobile') {
        this.$router.push({
          name: 'AddClientMobile',
          params: {
            actualTab: this.actualTab
          }
        })
      } else EventBus.$emit('dialog-add-client', this.actualTab)
    },
    activeButton (account, company, group, actualTab) {
      return userHasAccess(account, company, group, actualTab)
    }
  }
}
</script>
